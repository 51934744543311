<template>
  <div class="homePage" style="background: #f6f6f6;min-width: 375px;">
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" loading-text="正在加载..." @load="onLoad">
      <!-- 第一部分 -->
      <!-- <router-link :to="{ path: 'sousuo', query: { setid: 123123 } }"> -->
      <!-- <div class="sousuo">
          <van-search
            style="width: 80%;margin: 12px 10%;"
            v-model="value"
            shape="round"
            placeholder="请输入搜索关键词"
          />
        </div> -->
      <!-- </router-link> -->
      <!-- 第二部分 -->
      <div class="lunbo">
        <div>
          <van-swipe :height="140" :autoplay="3000" class="lunboyi">
            <van-swipe-item v-for="item in coursesSta" :key="item.id">
              <img class="lunboer" :height="140" :src="item.image_url_mobile" @click="tiaozhuanlist(item)" />
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
      <!-- 第四部分 -->
      <div @change='listCates(item.cate_id_chain)' v-for="(item, index) in coursesa" :key="index" class="dsbf">
        <div style="margin-top: 20px">
          <span class="gongkai">{{ item.name }}</span>
          <!-- <router-link
            :to="{ path: 'kechengziyemian', query: { setid: 1231231 } }"
          >
            <span style="margin-right: 20px" class="gengduo"
              >查看更多<van-icon name="arrow"
            /></span>
          </router-link> -->
        </div>
        <div class="rum">
          <van-tabs v-model="item.activeTab" swipe-threshold color="#61D1AC" title-inactive-color="#101010"
            line-height="4px" line-width="12px" @change="tabChange(index, item)">
            <van-tab v-for="item1 in item.child" :key="item1.id" :title="item1.name">
              <div>
                <div @click="skiprotorpage(item)" v-for="item in item1.courseList" :key="item.id" class="courseBox">
                  <div class="courseImg">
                    <img :src="item.cover" />
                  </div>
                  <div class="courseTitle">
                    <p style="word-break:keep-all;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">
                      {{ item.name }}</p>
                  </div>
                </div>
              </div>
            </van-tab>
          </van-tabs>
        </div>
      </div>
    </van-list>
    <!-- 底部 -->
    <!-- 公司信息 -->
    <div v-if="is_show == '2'" class="gongsihezi">
      <div class="gongsiwenz" @click="gongsixinxi()">
        <p>公司信息</p>
      </div>
      <div class="jishuzhichi">
        <p>珊瑚云直播提供技术支持</p>
      </div>
      <div class="tiaoguanw">
        <a href="https://www.shanhuketang.com/">www.shanhuketang.com</a>
      </div>
    </div>
    <!-- 底部tab -->
    <div style="margin-top: 48px">
      <van-tabbar active-color="#1872ed" v-model="active">
        <van-tabbar-item :to="{ path: 'course-home', query: { setid: 123123 } }"><span>首页</span>
          <template #icon="props">
            <img :src="props.active ? home.active : home.inactive" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item :to="{ path: 'myStudy', query: { setid: 123123 } }">
          <span>学习</span>
          <template #icon="props">
            <img :src="props.active ? study.active : study.inactive" />
          </template></van-tabbar-item>
        <van-tabbar-item :to="{ path: 'my', query: { setid: 123123 } }">
          <span>我的</span>
          <template #icon="props">
            <img :src="props.active ? my.active : my.inactive" />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { Lazyload, List } from 'vant'
Vue.use(Lazyload)
Vue.use(List)

export default {
  name: 'homeView',
  watch: {
    org_id: {
      handler( newVal ) {
        if (newVal) {
          this.listCates()
          this.listCourses()
          this.banners()
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      org_id: 'user/getOrgId'
    })
  },
  data() {
    return {
      value: '',
      coursesSta: [],
      active: 0,
      active1: [],
      list: [],
      loading: false,
      finished: false,
      domain: '',
      name: '',
      namea: '',
      coursesa: [],
      courses: [],
      is_show: '',
      my: {
        active: require('../assets/images/my-active.png'),
        inactive: require('../assets/images/my.png'),
      },
      study: {
        active: require('../assets/images/study-active.png'),
        inactive: require('../assets/images/study.png'),
      },
      home: {
        active: require('../assets/images/home-active.png'),
        inactive: require('../assets/images/home.png'),
      },
    }
  },
  created() {
    this.is_show = localStorage.getItem('tengface_is_show')
    // this.getOrgInfo()
  },
  methods: {
    getOrgInfo() {
      const data = {
        domain: window.location.host
        // domain: 'wx-adia.shanhuketang.com'
      }
      this.$api.post('/common/index/getOrgInfo', data)
        .then(res => {
          if (res.data.code == 200) {
            this.is_show = res.data.data.is_show
          }
        })
    },
    gongsixinxi() {
      this.$router.push({ path: '/lookover' })
      // this.$router.push({'path':'/lookover'});
    },
    tiaozhuanlist(item) {
      if (item.is_new_window == 2) {
        window.open(item.jump_path, '_self')
      } else {
        window.open(item.jump_path)
      }
    },
    skiprotorpage(item) {
      window.console.log("查询成功", item); //zhibo
      this.$router.push({ path: "/zhibo", query: { setid: item.id } });
    },
    banners() {
      // 首页banner列表
      const data = {
        org_id: this.org_id,
      }
      this.$api.post('/stu/course/banners', data)
        .then(res => {
          if (res.data.code === 200) {
            this.coursesSta = res.data.data
          }
        })
    },
    listCates() {
      // 查询科目书
      const data = {
        org_id: this.org_id,
        cate_id_chain: this.cate_id_chain
      }
      this.$api.post('/stu/cate/listCates', data)
        .then(res => {
          if (res.data.code === 200) {
            const tmpArr = res.data.data
            console.log(tmpArr)
            for (let index = 0; index < tmpArr.length; index++) {
              let item1 = tmpArr[index]
              let item = { cate_id_chain: item1.id, child: [], id: item1.id, name: "全部", pid: item1.id };
              item1.child.unshift(item)
              item1.activeTab = 0
              this.tabChange(index, item1)
            }
            console.log(tmpArr)
            this.coursesa = tmpArr
          }
        })
    },
    tabChange(index, item) {
      if (item.child.length > 0) {
        this.getCourseList(index, item.child[item.activeTab])
      } else {
        console.log('fffffff', item.child[item.activeTab])
      }
    },
    getCourseList(index, item) {
      const data = {
        org_id: this.org_id,
        cate_id_chain: item.cate_id_chain,
        page: 1,
        num: 100000
      }
      this.$api.post('/stu/course/listCourses', data)
        .then(res => {
          if (res.data.code === 200) {
            item.courseList = res.data.data.list
            this.$set(this.coursesa, index, this.coursesa[index])
          }
        })
    },
    listCourses() {
      const data = {
        org_id: this.org_id,
        cate_id_chain: this.cate_id_chain
      }
      this.$api.post('/stu/course/listCourses', data)
        .then(res => {
          if (res.data.code === 200) {
            this.courses = res.data.data.list
          }
        })
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1)
        }

        // 加载状态结束
        this.loading = false

        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true
        }
      }, 1000)
    }
  }
}
</script>

<style scoped>
/* 公司信息 */
.gongsihezi {
  height: 188px;
  background: #fff;
  text-align: center;
  overflow: hidden;
}

.gongsiwenz {
  width: 100px;
  text-align: center;
  line-height: 30px;
  /* border-left: 1px solid #000;
	border-right: 1px solid #000; */
  margin: 0 auto;
  margin-top: 20px;
}

.gongsiwenz p {
  color: #000;
}

.jishuzhichi {
  margin-top: 10px;
  text-align: center;
}

.jishuzhichi p {
  color: #000;
}

.tiaoguanw {
  text-align: center;
  margin-top: 10px;
}

.tiaoguanw a {
  color: #000;
}

/* 第一部分 */
.homePage>>>.van-search {
  width: 300px;
  height: 36px;
  background: linear-gradient(90deg, #e7fff5 1%, #d9f6fe 86%, #d7f5ff);
  border-radius: 18px;
}

.homePage>>>.van-search__content--round {
  background: linear-gradient(90deg, #e7fff5 1%, #d9f6fe 86%, #d7f5ff);
}

.homePage>>>.van-tabs__line {
  background: linear-gradient(98deg, #73aaff 0%, #0053ff 100%);
}

.homePage>>>.van-swipe__indicator--active {
  width: 0.43rem;
  height: 0.13rem;
  background: #ffffff;
  border-radius: 0.08rem;
}

.sousuo {
  width: 100%;
  height: 44px;
  background: #ffffff;
}

/* 第二部分 */
.lunbo {
  width: 100%;
}

.lunboyi {
  margin: 10px 10px 0px 10px;
  border-radius: 10px;
}

.lunboer {
  width: 100%;
}

.wenzi {
  font-size: 12px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: center;
  color: #101010;
}

.tu {
  width: 48px;
  height: 48px;
}

.ke {
  margin-left: 20px;
}

.jinpingss {
  width: 100%;
}

/* 第三部分 */
.tiyan {
  width: 100%;
  height: 382px;
  background: #fefffe;
  border-radius: 10px;
  margin: 0 auto;
  overflow: hidden;
}

.gongkai {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: #101010;
  margin-left: 12px;
}

.gengduo {
  font-size: 12px;
  font-weight: 400;
  text-align: right;
  color: #888888;
  margin-right: 20px;
  float: right;
}

.santu {
  width: 130px;
  height: 88px;
}

.shoulian {
  margin-top: 0px;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #101010;
  margin-left: 12px;
}

.zbz {
  width: 54px;
  height: 18px;
  background: #e6f6f0;
  border-radius: 4px;
  margin-top: -7px;
  margin-left: 12px;
}

.jinru {
  width: 72px;
  height: 24px;
  background: linear-gradient(95deg, #78d4b6 4%, #0bb67e 95%);
  border-radius: 16px;
  margin-top: 17px;
  margin-left: 160px;
}

.zzz {
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  color: #10a675;
}

.jinzb {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #fefffe;
  text-align: center;
  line-height: 27px;
}

.shijian {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #10a675;
  margin-left: 12px;
  margin-top: 47px;
}

.shijianyi {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #10a675;
  margin-left: 12px;
  margin-top: 27px;
}

/* 第四部分 */
.dsbf {
  width: 9.47rem;
  margin-left: 0.27rem;
  background: #fefffe;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 0.27rem;
  margin-bottom: 0.53rem;
}

.homePage .rum>>>.van-tabs__wrap--scrollable .van-tab {
  padding: 0px 12px;
  flex: 0 0 auto;
}

.homePage .rum>>>.van-tabs__nav--line.van-tabs__nav--complete {
  padding-left: 0px !important;
}

.homePage .rum>>>.van-tab--active {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  color: #101010;
}

.chrm {
  width: 4.27rem;
  margin-bottom: 10px;
  margin-left: 20px;
  float: left;
}

.homePage .courseBox {
  float: left;
  width: 4.27rem;
  margin-top: 0.24rem;
}

.homePage .courseBox:nth-child(odd) {
  margin-left: 0.32rem;
}

.homePage .courseBox:nth-child(even) {
  margin-left: 0.29rem;
}

.homePage .courseBox .courseImg {
  width: 100%;
  height: 2.83rem;
}

.homePage .courseBox .courseImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.3rem;
}

.homePage .courseBox .courseTitle {
  width: 100%;
  height: 0.8rem;
  font-size: 0.43rem;
  font-weight: 600;
  color: #101010;
}

.homePage .courseBox .courseTitle p {
  margin: 0px;
  padding: 0px;
  margin-top: 0.13rem;
  height: 0.56rem;
  overflow: hidden;
}

.chrma {
  width: 160px;
  height: 100%;
  margin-bottom: 10px;
  margin-right: 20px;
  float: right;
}

.tumen {
  width: 160px;
  height: 106px;
  border-radius: 6px;
}

.ai {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #101010;
  margin: 5px 0px 6px 0px;
}

.shierj {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #888888;
  margin-top: 6px;
}

/* 第七部分 */
.dsbfyi {
  width: 375px;
  height: 100%;
  background: #fefffe;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 10px;
}

.jiazai {
  font-size: 12px;
  font-weight: 400;
  color: #888888;
}
</style>
