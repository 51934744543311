<template>
    <div>
       <div style="background: #f5f5f5;height: 100vh;">
        <NavigateBar :title="'编辑资料'" @handleBack="goOff" />
            <!-- <div class="tit tit_fh">
        <div @click="goOff()"  class="img" style="width: 22px;height: 22px;padding: 15px 22px 0;">
          <img style="width: 16px;height:16px" src="../../assets/images/ico_fanhui.png" alt="">
        </div>
        <h1 style="width: 85px; margin: -10px 160px;">编辑资料</h1>
        </div> -->
        <div class="jieshao">
        <div class="nc avatar">
          <div class="nc_l">头像</div>
          <van-uploader style="border-radius: 40px;" ref="uploader" :after-read="afterRead" :deletable="false"   :max-count="1">
            <img v-if="icon" @click="choosePic" style="width: 60px;border-radius: 50%;height: 60px;"  :src="icon" />
            <van-icon style="margin-top: 14px;margin-left: 15px;" size="40" v-else name="plus" />
         </van-uploader>
        </div>
        <div class="nc nc_fir">
            <div class="nc_l">昵称</div>
            <!-- <div class="nc_r">一只会飞的猪</div> -->
            <input class="nc_r" v-model="nick_name" @blur="updateUserInfo" style="width: 160px;height: 40px;border: none;" type="text">
        </div>
        <!-- <div class="nc">
            <div class="nc_l">职业</div>
            <div class="nc_r" style="color: #888888;">请输入...</div>
        </div> -->
        <div class="nc">
            <div class="nc_l">性别</div>
            <div class="nc_r">
            <div class="radio man" @click="updateSexInfo(1)">
                <input type="radio" id="sex1" name="sex">
                <label for="sex1" :class="{'select':sex===1}"></label>
                &nbsp;男
            </div>
            <div class="radio" @click="updateSexInfo(2)">
                <input type="radio" id="sex2" name="sex">
                <label for="sex2" :class="{'select':sex===2}"></label> 女
            </div>
            </div>
        </div>
        <router-link
            :to="{ path: 'gaishoujihao', query: { setid: 1231231 } }"
          >
        <div class="nc nc_fir" v-if="is_showphone_hidden">
            <div class="nc_l">手机</div>
            <div class="nc_r">
              <div class="gengduo">
                  <p style="color: #888888;">已绑定</p>
                  <a href="#" >
                  <img src="../../assets/images/ico_gengduo_2.png" alt=""></a>
              </div>
            </div>
        </div>
        </router-link>
        </div>
        <div style="display:flex;justify-content:center;margin-top:50px">
         <van-button class="signOutBtn" @click="deletetoken()" size="normal" type="default">退出登录</van-button>
         </div>
       </div>
    </div>
</template>
<script>
import { Toast, Dialog } from 'vant'
import COS from 'cos-js-sdk-v5'
import common from '@/utils/common'
import NavigateBar from "../myHomework/navigateBar.vue";
export default {
  name: 'gitList',
  data () {
    return {
      nick_name: '',
      fileList: [],
      icon: '',
      sex: '',
      cos: null,
      is_showphone_hidden:false
    }
  },
  components: {
    NavigateBar,
  },
  created () {
    // this.updateUserInfo()
    this.getUserInfo()
    this.getconfig()
    // 初始化实例
    this.cos = new COS({
    // getAuthorization 必选参数
      getAuthorization: function (options, callback) {
        // 异步获取临时密钥
        // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
        // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
        // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048

        var url = `${process.env.VUE_APP_API_ONLINE}/onlineapi/common/index/getCosSign` // url替换成您自己的后端服务
        var xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.onload = function (e) {
          try {
            var data = JSON.parse(e.target.responseText)
            var credentials = data.data.credentials
          // eslint-disable-next-line no-empty
          } catch (e) {
          }
          if (!data || !credentials) {
            return console.error('credentials invalid:\n' + JSON.stringify(data, null, 2))
          }
          // eslint-disable-next-line standard/no-callback-literal
          callback({
            TmpSecretId: credentials.tmpSecretId,
            TmpSecretKey: credentials.tmpSecretKey,
            SecurityToken: credentials.sessionToken,
            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
            StartTime: data.data.startTime, // 时间戳，单位秒，如：1580000000
            ExpiredTime: data.data.expiredTime // 时间戳，单位秒，如：1580000000
          })
        }
        xhr.send()
      }
    })
  },
  methods: {
    goOff () {
      this.$router.go(-1)
    },
    choosePic () {
      this.$refs.uploader.chooseFile()
    },
    afterRead (file) {
      // 此时可以自行将文件上传至服务器
      console.log(file)
      this.cos.putObject({
        Bucket: 'tengface-live-user-1308721187', /* 填入您自己的存储桶，必须字段 */
        Region: 'ap-guangzhou', /* 存储桶所在地域，例如ap-beijing，必须字段 */
        Key: (new Date()).getTime() + file.file.name, /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
        StorageClass: 'STANDARD',
        Body: file.file, // 上传文件对象
        onProgress: function (progressData) {
          console.log(JSON.stringify(progressData))
        }
      }, (err, data) => {
        console.log(err || data)
        if (data.statusCode === 200) {
          this.icon = `https://${data.Location}`
          this.updateHeaderInfo(this.icon)
        }
      })
    },
    updateHeaderInfo (imgurl) {
      // 修改用户信息
      const data = {
        icon: imgurl
      }
       this.$api.post('/user/index/updateUserInfo', data)
        .then(res => {
          if (res.data.code === 200) {
            Toast('修改成功')
          }
        })
    },
    getUserInfo () {
      // 获取用户信息
      const data = {

      }
       this.$api.post('/user/index/getUserInfo', data)
        .then(res => {
          if (res.data.code === 200) {
            this.nick_name = res.data.data.nick_name
            this.icon = res.data.data.icon
            this.sex = res.data.data.sex
          }
        })
    },
    async getconfig(){
      const res = await  this.$api.post('/user/index/config')
      const { data } = res
      if (data.code === 200) {
        // console.log(data.data,'data');
        let lengh=data.data.filter(x=>x.key=='Online_school_mobile_phone_hidden')
        this.is_showphone_hidden=lengh[0].value=='true'?false:true
        // console.log(this.is_showphone_hidden,'is_show');
      }
    },
    updateSexInfo (sex) {
      this.sex = sex
      // 修改用户信息
      const data = {
        sex: sex
      }
       this.$api.post('/user/index/updateUserInfo', data)
        .then(res => {
          if (res.data.code === 200) {
            Toast('修改成功')
          }
        })
    },
    updateUserInfo () {
      if (!this.nick_name) {
        console.log('不能为空!')
        Toast('不能为空')
        return
      }
      // 修改用户信息
      const data = {
        nick_name: this.nick_name
      }
       this.$api.post('/user/index/updateUserInfo', data)
        .then(res => {
          if (res.data.code === 200) {
            Toast('修改成功')
          }
        })
    },
    deletetoken () {
      function beforeClose (action, done) {
        if (action === 'confirm') {
          setTimeout(done, 1000)
        } else {
          done()
        }
      }
      Dialog.confirm({
        title: '退出登录',
        message: '确定要退出登录，并返回主页吗？',
        beforeClose
      }).then(() => {
        localStorage.clear()
        Toast.success('退出成功')
        // this.$router.push({
        //   path: '/'
        // })
        // 去掉url中的token
        const url = common.deleteUrlParams('token')
        const index = url.indexOf('/#/')
        location.href = `${url.substring(0, index)}/#/`
      })
    }
  }
}
</script>
<style scoped>
.signOutBtn{
  width: 315px;
  height:44px;
  font-size: 16px;
  font-weight: 400;
  color:#FF0B0B;
  border:none;
  border-radius: 20px;
}
.pic >>> .van-uploader__upload{
  border-radius: 40px;
}
.pic >>> .van-image__img{
  border-radius: 40px;
}
    * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  font-family: PingFangSC, PingFangSC-Regular;
  background: #f6f6f6;

}

a {
  text-decoration: none;
}


.tit {
  width: 100%;
  height: 44px;
  background-color: #ffffff;
}

.tit h1 {
  float: left;
  width: 100px;
  height: 24px;
  font-size: 17px;
  font-weight: 600;
  color: #101010;
  line-height: 24px;
  margin: 10px 150px 0;
}

.touxiang {
  width: 100%;
  height: 100px;
  background: #ffffff;
}

.touxiang .pic {
  float: left;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  margin: 18px 20px;
}

.touxiang .wenzi {
  float: left;
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #202020;
  line-height: 20px;
  margin-top: 40px;
}

.touxiang .gengduo {
  float: right;
  width: 16px;
  height: 16px;
  margin: 40px 16px;
}

.jieshao {
  width: 100%;
}

.jieshao .nc {
  /* float: left; */
  width: 100%;
  height: 60px;
  background: #ffffff;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.jieshao .avatar{
  height: 80px;
  padding-right: 20px;
}
.jieshao .nc_fir {
  margin-top: 10px;
}

.jieshao .nc .nc_l {
  float: left;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #202020;
  line-height: 20px;
  margin: 16px 20px;
}

.jieshao .nc .nc_r {
  float: right;
  width: 100px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  text-align: right;
  color: #303133;
  line-height: 20px;
  margin: 15px 20px;
}

.nc_r .gengduo p {
  float: left;
  height: 20px;
  color: #888888;
  line-height: 20px;
  margin-left: 30px;
}

.nc_r .gengduo a {
  float: right;
  width: 14px;
  height: 14px;
  margin-top: 2px;

}

.radio {
  position: relative;
  display: inline-block;
}
.man{
  margin-right: 18px;
}
.radio input {
  width: 15px;
  height: 15px;
  appearance: none;
  /*清楚默认样式*/
  -webkit-appearance: none;
  opacity: 0;
  outline: none;
}

.radio label {
  position: absolute;
  left: -2px;
  top: 3px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #888888;
}

.radio .select::after {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  width: 17px;
  height: 17px;
  background: url(../../assets/images/xuanzhong.png) no-repeat center;
  background-size: cover;
  border-radius: 50%;
}
::v-deep .van-nav-bar__title{
  font-size: 17px;
  font-weight: 600;
  color: #101010;
}
::v-deep .van-nav-bar .van-icon{
  color: #323233;
}
::v-deep .van-hairline--bottom::after{
  border-bottom-width: 0px;
}
</style>
