<template>
  <div class="detail">
     <van-nav-bar
         title="笔记详情"
         left-arrow
         @click-left="back"
     />
     <div class="head" @click="toVideo">
       <img v-if="!detail.is_video_delete" :src="detail.cover_url" alt="">
       <img v-else src="@/assets/images/note-lapse.png" alt="">
       <div class="note-title">
         <div class="tilt">{{detail.name}}</div>
         <van-icon name="arrow" />
       </div>
     </div>
     <div class="text">
     <div class="note-text" id="note" v-html="detail.content"></div>
     <div class="nodeContainer" v-for="(item,index) in detail.node_mark" :key="index" @click="toNode(item)">
            <img src="@/assets/images/note-sign.png" alt="">
            <span class="time">{{item.time}}</span>
            <span>{{item.title}}</span>
     </div>
      </div>
  </div>
</template>

<script>
import {Toast} from 'vant'
export default {
   data(){
    return{
      detail:{},
    }
   },
   created(){
     this.getDetail()
   },
   updated(){
     const div=document.getElementById('note')
     let img=div.getElementsByTagName('img')
     console.log('[<-- img -->]',img);
     for(let i=0;i<img.length;i++){
       img[i].style.width="9.2rem"
       img[i].style.height="6rem"
     }
   },
   methods:{
    toVideo(){
       if (this.detail.is_video_delete) {
          return Toast('视频已删除')
       }else{
         this.$router.push({
        path: "/zhibo",
        query: {
          setid: this.$route.query.course_id,
          class_id: this.detail.class_id,
          view_time:this.$route.query.view_time,
          video_id: this.$route.query.video_id,
          chapter_id: this.$route.query.chapter_id,
          sectionId: this.detail.section_id,
          name: this.detail.name,
        },
      });
       }
    },
    back(){
      this.$router.go(-1)
    },
   async getDetail(){
      const data={
        chapter_id:this.$route.query.chapter_id,
        video_id:this.$route.query.video_id
      }
      const res=await this.$api.post('/stu/course/getNoteDetail',data)
      console.log('[<-- res -->]',res);
      if (res.data.code==200) {
        this.detail=res.data.data
        console.log('[<-- this.detail -->]',this.detail);
      }
      
    },
   },
}
</script>

<style lang="scss" scoped>
::v-deep .van-nav-bar__title{
  font-size: 16px;
  font-weight: 600;
  color: #101010;
}
::v-deep .van-nav-bar .van-icon{
  color: #323233;
}
::v-deep .van-hairline--bottom::after{
  border-bottom-width: 0px;
}
::v-deep .van-icon{
  display: block;
  font-size: 16px;
  color: #323233;
}
.detail{
  background: #f6f6f6;
}
.head{
  padding: 0.4rem;
  display: flex;
  justify-content: start;
  background: #fff;
  img{
    width: 90px;
    height: 66px;
    margin-right: 12px;
    border-radius: 6px;
  }
  .note-title{
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tilt{
     width: 5.5rem;
     word-wrap: break-word;
     overflow-wrap: break-word;
     white-space: normal;
     font-size: 15px;
     font-weight: 500;
     color: #202020;
    }
  }
}
.text{
  background: #fff;
  margin-top: 10px; 
  padding: 0.2rem 0.4rem;
  font-size: 14px;
  font-weight: 400;
  color: #202020;
  width: 100%;
  box-sizing: border-box;
  .note-text{
   width: 9.2rem;
   white-space: normal
  }
}
 .nodeContainer{
  width:fit-content;
  height: 0.5rem;
  background: #f1f2f7;
  border-radius: 40px;
  padding: 7px 16px;
  font-size: 14px;
  font-weight: 400;
  color: #202020;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 10px;
  img{
    height: 16px !important;
    width: 16px !important;
    margin-right: 6px;
  }
  .time{
    margin-right: 10px;
  }
  .videoNodeTitle{
    display: inline-block;
    white-space:nowrap;
  }
}
</style>