/**
 * 描述：合同私有桶上传
**/
import COS from 'cos-js-sdk-v5'
import store from '../store/store'

let privateCos = null
let taskId = null

export function initCos() {
    privateCos = new COS({
        getAuthorization: function (options, callback) {
            var url = process.env.VUE_APP_API_ONLINE_SCHOOL + '/common/index/getCosPrivateSign2'
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url, true)
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4 && (xhr.status == 200 || xhr.status == 0)) {
                    try {
                        //监听HTTP状态码
                        const {
                            code,
                            data: { credentials, startTime, expiredTime }
                        } = JSON.parse(xhr.responseText)
                        if (code !== 200) {
                            console.log('获取临时密钥失败')
                            return
                        }
                        callback({
                            TmpSecretId: credentials.tmpSecretId,
                            TmpSecretKey: credentials.tmpSecretKey,
                            SecurityToken: credentials.sessionToken,
                            StartTime: startTime, // 时间戳，单位秒，如：1580000000
                            ExpiredTime: expiredTime // 时间戳，单位秒，如：1580000000
                        })
                    } catch (error) {
                        console.log('error: ', error)
                    }
                }
            }
            xhr.send()
        }
    })
}

export function privateCosUpload(file, needFid, file_name, callback) {
    if (!privateCos) {
        initCos()
    }
    return new Promise((resolve, reject) => {
        const time = new Date()
        const y = time.getFullYear()
        const m = two(time.getMonth() + 1)
        const d = two(time.getDate())
        const name = file_name || file.name
        const fileName = randomString() + '_' + name
        const uploadPath = '/' + store.state.user.org_id + '/contract'
        const storeAs = uploadPath + '/' + fileName
        privateCos.getObjectUrl(
            {
                Bucket: process.env.VUE_APP_PRIVATE_BUCKET,
                /* 填入您自己的存储桶，必须字段 */
                Region: 'ap-nanjing',
                /* 存储桶所在地域，例如ap-beijing，必须字段 */
                Key: storeAs,
                /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
                StorageClass: 'STANDARD',
                // Body: file, // 上传文件对象
                Method: 'PUT',
                Sign: true,
            },
            async (err, data) => {
                if (err) {
                    return console.log(err)
                }
                var xhr = new XMLHttpRequest()
                xhr.open('PUT', data.Url, true)
                xhr.onload = function (e) {
                    const urlInstance = new URL(xhr.responseURL)
                    const url = `${urlInstance.origin}${urlInstance.pathname}`
                    // 文件链接必须decode，否则带中文存储的是转义后的地址，在私有桶找不到
                    resolve({ url: decodeURIComponent(url) })
                }
                xhr.onerror = function (e) {
                    reject(e)
                };
                xhr.send(file)
            }
        )
    }, err => {
        console.log('err: ', err)
    })
}

function two(num) {
    if (num < 10) {
        return '0' + num
    }
    return num
}

function randomString(len) {
    len = len || 8
    const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
    const maxPos = chars.length
    let pwd = ''
    for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return pwd
}
