<template>
  <div class="container">
    <header>
      <img :src="icon" alt="" />
      <div class="nameBox">
        <p>{{ nick_name }}</p>
        <p>班级门数: {{ classNum }}</p>
      </div>
      <div class="editBox" @click="editMessage">编辑资料</div>
    </header>

    <main>
      <p class="myFunction">我的功能</p>
      <div class="functionBox">
        <div
          class="functionItem"
          v-for="(item, index) of functionList"
          :key="index"
          @click="toPath(item)"
          :style="{ background: item.backgroundColor }"
        >
          <img :src="item.imgUrl" alt="" />
          <p>{{ item.title }}</p>
        </div>
      </div>
    </main>

    <van-popup
      class="phoneVanpopup"
      v-model="show"
    >
    <div>
      <p class="kefu">客服电话</p>
      <p class="kefudianhua">
        {{ web_contact_number }}
      </p>
      <div class="call">
        <span @click="gitList()" class="quxiao">取消</span>
        <span @click="callPhone" class="lijiboda">立即拨打</span>
      </div>
      </div>
      <!-- <div class="empty" v-else>
      <img class="emptyImg" src="../../assets/images/empty_phone.png" alt="">
      <span class="emptyPhoneText">暂未配置客服电话</span>
      </div> -->
    </van-popup>

    <!-- 尾部 -->
    <div style="margin-top: 48px">
      <van-tabbar active-color="#1872ed" v-model="active">
        <van-tabbar-item icon="home-o" :to="{ path: '/' }">
          <span>首页</span>
          <template #icon="props">
            <img :src="props.active ? home.active : home.inactive" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item :to="{ path: 'myStudy', query: { setid: 123123 } }">
          <span>学习</span>
          <template #icon="props">
            <img :src="props.active ? study.active : study.inactive" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item
          :name="3"
          :to="{ path: 'my', query: { setid: 123123 } }"
        >
          <span>我的</span>
          <template #icon="props">
            <img :src="props.active ? my.active : my.inactive" />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Popup } from "vant";

Vue.use(Popup);
export default {
  data() {
    return {
      show: false,
      active: 3,
      nick_name: "",
      icon: "",
      classNum: "",
      web_contact_number: "",
      my: {
        active: require("../../assets/images/my-active.png"),
        inactive: require("../../assets/images/my.png"),
      },
      study: {
        active: require("../../assets/images/study-active.png"),
        inactive: require("../../assets/images/study.png"),
      },
      home: {
        active: require("../../assets/images/home-active.png"),
        inactive: require("../../assets/images/home.png"),
      },
      functionList: [
         {
          title: "我的作业",
          imgUrl: require("../../assets/images/ico_homework@2x.png"),
          backgroundColor: "#fffaed",
          toPath: "homework",
        },
         {
          title: "历史观看",
          imgUrl: require("../../assets/images/aglook.png"),
          backgroundColor: "#fdf4f5",
          toPath: "HistoryViewing",
        },
          {
          title: "合同管理",
          imgUrl: require("../../assets/images/dianzihtt.png"),
          backgroundColor: "#f1f6ff",
          toPath: "contractmanagement",
          query: {
            setid: 123123,
          },
        },
        
        {
          title: "我的评测",
          imgUrl: require("../../assets/images/pingtest.png"),
          backgroundColor: "#f2f6fe",
          toPath: "pingtest",
          query: {
            setid: 123123,
          },
        },
        {
          title: "客服热线",
          imgUrl: require("../../assets/images/kefuertu.png"),
          backgroundColor: "#f3fff6",
          toPath: "kefuertu",
        },
        {
          title: "我的笔记",
          imgUrl: require("../../assets/images/my-note.png"),
          backgroundColor: "#fff6f1",
          toPath: "myNote",
        }
        ,
        {
          title: "关于我们",
          imgUrl: require("../../assets/images/guanyuertu.png"),
          backgroundColor: "#f6f4ff",
          toPath: "guanyu",
          query: {
            setid: 123123,
          },
        }
      ],
    };
  },
  created() {
    this.getUserInfo();
    this.web_contact_number = localStorage.getItem("web_contact_number");
  },
  mounted() {},
  methods: {
    editMessage() {
      this.$router.push({ path: "ziliao", query: { setid: 123123 } });
    },
    toPath(item) {
      if (item.title === "客服热线") {
        this.show = true;
      } else {
        this.$router.push({ path: item.toPath, query: item.query });
      }
    },
    callPhone() {
      window.location.href = "tel://" + this.web_contact_number;
    },
    gitList() {
      this.show = false;
    },
    showPopup() {
      this.show = true;
    },
    // handleClose (done) {
    // },
    getUserInfo() {
      // 获取用户信息
      const data = {};
      this.$api.post("/user/index/getUserInfo", data).then((res) => {
        if (res.data.code === 200) {
          this.$store.commit("user/setFansId", res.data.data.id);
          localStorage.setItem("tengface_fans_id", res.data.data.id);
          this.nick_name = res.data.data.nick_name;
          this.icon = res.data.data.icon;
          this.classNum = res.data.data.classNum;
          localStorage.setItem("nick", res.data.data.nick_name);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 40px 20px 0;
  background-image: linear-gradient(160deg, #e0edff 10%, #fff 50%);

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    margin-bottom: 1.5rem;

    img {
      width: 1.81rem;
      height: 1.81rem;
      border-radius: 50%;
      margin-right: 20px;
    }

    .nameBox {
      flex: 1;
      color: #101010;

      & p:first-child {
        font-size: 20px;
        color: #202020;
        font-weight: 400;
        margin-bottom: 0px;
      }

      & p:last-child {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        margin-top: 9px;
      }
    }

    .editBox {
      width: 1.92rem;
      height: 0.75rem;
      line-height: 0.75rem;
      border-radius: 16px;
      text-align: center;
      color: #fff;
      background: linear-gradient(133deg, #73aaff 3%, #0053ff 91%);
    }
  }

  main {
    .myFunction {
      font-size: 16px;
      font-weight: 400;
    }
    .functionBox {
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;
      // align-items: center;
      margin-top: 20px;
      .functionItem {
        margin-left: 11px;
        float: left;
        box-sizing: border-box;
        width: 100px;
        height: 100px;
        border-radius: 10px;
        text-align: center;
        padding: 15px 0;
        margin-bottom: 20px;
        box-shadow: 0px 4px 10px 0px rgba(26,88,172,0.06); 
        img {
          width: 30px;
          height: 30px;
        }
        &:nth-child(3),
        &:nth-child(6){
          margin-right: 0;
        }
      }
    }
  }
}
.phoneVanpopup{
   width: 280px;
   height: 170px;
   background: #ffffff;
   border-radius: 10px;
   text-align: center;
}
.kefudianhua {
  font-size: 28px;
  font-family: DINPro, DINPro-Bold;
  font-weight: 400;
  text-align: left;
  color: #101010;
  text-align: center;
  margin-top: 8px;
  margin-bottom:20px
}
.call{
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
}
.kefu {
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #101010;
  text-align: center;
  margin-top: 14px;
}
.empty{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: center;
  .emptyImg{
  left: 50%;
  width: 121px;
  height: 121px;
}
.emptyPhoneText{
  color: #666666;
  font-size: 14px;
  font-weight: 400;
}
}
.lijiboda {
  background: linear-gradient(132deg, #73aaff 2%, #0053ff 93%);
  border-radius: 17px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 26px;
}
.quxiao {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #101010;
  background: #efefef;
  border-radius: 17px;
  padding: 8px 37px;
}
</style>
