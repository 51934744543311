import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import api from '@/api/onlineSchool'
import Vant from 'vant'
import common from './utils/common'
import VueClipBoard from 'vue-clipboard2'
import VConsole from 'vconsole';
import commApiRequest from '@/api/commApiRequest'

Vue.use(VueClipBoard)
import 'vant/lib/index.css'
import 'lib-flexible'
import '@/utils/auto-import'
import './router/permission'

Vue.use(Vant)
Vue.prototype.$api = api
Vue.prototype.$dataApi =  commApiRequest(process.env.VUE_APP_API_DATA)
Vue.prototype.$liveApi =  commApiRequest(process.env.VUE_APP_API_ROOT)
Vue.prototype.$onlineApi = commApiRequest(process.env.VUE_APP_API_ONLINE)
Vue.prototype.$subApi  = commApiRequest(process.env.VUE_APP_API_SUBTITLES)
Vue.config.productionTip = false

// 正式环境根据设备类型 跳转终端
if(process.env.NODE_ENV == 'production'){
  if(!location.hash.includes('checkMobile')) {
    common.checkEquipment('h5')
  }
} else {
  new VConsole()
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
