<!--图文互动-->
<template>
    <div id="QuillEditor" class="QuillEditor">
        <quill-editor ref="myTextEditor" v-model="quillEditor" :image-tag-class="'img-size'" :options="editorOption" @ready="onEditorReady($event)" />
        <input
            id="selectfileObj"
            type="file"
            style="display: none"
            @click="
                e => {
                    e.target.value = ''
                }
            "
            @change="getFilef($event)"
        />
        <!--视频上传弹窗-->
        <!-- <div>
            <el-dialog :close-on-click-modal="false" width="50%" style="margin-top: 1px" title="视频上传" :visible.sync="videoForm.show" append-to-body>
                <el-tabs v-model="videoForm.activeName">
                    <el-tab-pane label="添加视频链接" name="first">
                        <el-input v-model="videoForm.videoLink" placeholder="请输入视频链接" clearable></el-input>
                        <el-button type="primary" size="small" style="margin: 20px 0 0 0" @click="insertVideoLink(videoForm.videoLink)">确认</el-button>
                    </el-tab-pane>
                    <el-tab-pane label="本地视频上传" name="second">
                        <el-upload
                            v-loading="loading"
                            style="text-align: center"
                            drag
                            :action="uploadVideoConfig.uploadUrl"
                            accept="video/*"
                            :name="uploadVideoConfig.name"
                            :before-upload="onBeforeUploadVideo"
                            :multiple="false"
                        >
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">
                                将文件拖到此处，或
                                <em>点击上传</em>
                            </div>
                        </el-upload>
                    </el-tab-pane>
                </el-tabs>
            </el-dialog>
        </div> -->
    </div>
</template>

<script>
// import { container } from 'quill-image-extend-module'
// Quill.register('modules/ImageExtend', ImageExtend)
import { quillEditor, Quill } from 'vue-quill-editor'
// Vue.use(VueQuillEditor)
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// import { mapState } from 'vuex'
import { getRandName, cosUpload } from '@/utils/cos'
// import Video from '@/util/quill-video'
import Image from '@/utils/quill-image'
import {Toast} from 'vant'
// import ImageResize from "quill-image-resize-module"; // 引用
// Quill.register("modules/imageResize", ImageResize); // 注册
// Quill.register(Video, true)
Quill.register(Image, true)

export default {
    name: 'TFQuillEditor',
    components: {
        quillEditor
    },
    props: {
        quillEditorValue: {
            type: String,
            default: ''
        },
        // 视频上传配置
        uploadVideoConfig: {
            type: Object,
            default() {
                return {
                    uploadUrl: '', // 上传地址
                    maxSize: 10, // 图片上传大小限制，默认不超过2M
                    name: 'Filedata' // 图片上传字段
                }
            }
        }
    },
    data() {
        return {
            quillEditor: '',
            editorOption: {
                placeholder:'请输入笔记内容',
                modules: {
                    toolbar: {
                        container:[
                           ['bold', 'italic', 'underline', 'strike'], 
                           [{'list': 'ordered'}, {'list': 'bullet'}],
                           [{'size': ['small', false, 'large', 'huge']}],
                           [{'color': []}, {'background': []}], 
                           ['image'],
                           ['insertMetric'],
                           ['sign']
                        ],
                        handlers: {
                            insertMetric:()=>{
                              this.$emit('signCut')    
                            },
                            sign:()=>{
                                this.$emit('videoNode')
                            },
                            image: function () {
                                // 劫持原来的图片点击按钮事件
                                // QuillWatch.emit(this.quill.id)
                                document.getElementById('selectfileObj').click()
                            },
                            video: () => {
                                this.videoForm.show = true
                            }
                        }
                    }
                }
            },
            uploader: '',
            accessid: '',
            accesskey: '',
            host: '',
            policyBase64: '',
            signature: '',
            callbackbody: '',
            key: 'uploads/comment/',
            expire: 0,
            g_object_name: 'live',
            g_object_name_type: 'random_name',
            now: Date.parse(new Date()) / 1000,
            timestamp: Date.parse(new Date()) / 1000,
            changefileName: '',
            uploadpathURL: '/uploads/comment',
            // 视频上传变量
            videoForm: {
                show: false, // 显示插入视频链接弹框的标识
                videoLink: '',
                activeName: 'first'
            },
            loading: false
        }
    },
    computed: {
        // ...mapState({
        //     uid: state => state.user.uid
        // }),
        editor() {
            return this.$refs.myTextEditor.quillEditor
        }
    },
    watch: {
        quillEditorValue: function () {
            this.quillEditor = this.quillEditorValue
        },
        quillEditor: function () {
            this.$emit('editorContentChange', this.quillEditor)
        }
    },
    created() {
        this.quillEditor = this.quillEditorValue
    },
    mounted(){
        this.initButton();
        this.initSign()
    },
    methods: {
        initSign(){
         const sourceEditorButton = document.querySelector('.ql-sign');
         sourceEditorButton.innerHTML = '<div class="video-cut"></div>';
        },
        initButton() {
         const sourceEditorButton = document.querySelector('.ql-insertMetric');
         sourceEditorButton.innerHTML = '<div class="video-cut"></div>';
        },
        getMyTextEdit(){
          return this.$refs.myTextEditor.quill
        },
        onEditorReady(quill) {
            quill.root.addEventListener(
                'paste',
                e => {
                    console.log('复制')
                    let clipboardData = e.clipboardData
                    let i = 0
                    let items, item, types

                    if (clipboardData) {
                        items = clipboardData.items

                        if (!items) {
                            return
                        }
                        item = items[0]
                        types = clipboardData.types || []

                        for (; i < types.length; i++) {
                            if (types[i] === 'Files') {
                                item = items[i]
                                break
                            }
                        }
                        if (item && item.kind === 'file' && item.type.match(/^image\//i)) {
                            e.preventDefault()
                            let file = item.getAsFile()
                            this.updatefile(file)
                        }
                    }
                },
                false
            )
        },
        getFilef(e) {
            const file = e.target.files[0]
            this.updatefile(file)
        },
        async updatefile(file) {
            console.log('[<-- file.name -->]',file.name);
            
            const imageExtensions = ["jpg","jpeg","png","gif","bmp","svg","webp",];
            const fileExtension = file.name.toLowerCase().split(".").pop();
            console.log('[<-- fileExtension -->]',fileExtension);
            console.log('[<-- imageExtensions.includes(fileExtension) -->]',imageExtensions.includes(fileExtension));
            
             if (!imageExtensions.includes(fileExtension)) {
                return Toast('只能上传图片')
            }
            const { url } = await cosUpload(file, false, getRandName(file.name))
            console.log('[<-- 富文本编辑器里面的url -->]',url);
            let quill = this.$refs.myTextEditor.quill
            // 如果上传成功返回图片URL
            if (quill && url) {
                // 获取光标所在位置
                let length = this.quillEditor.length // quill.getSelection().index;
                // 插入图片  res.info为服务器返回的图片地址
                quill.length = length
                let index = quill.selection.savedRange.index
                // 插入图片，res为服务器返回的图片链接地址
                quill.insertEmbed(index, 'image', {
                    url: url,
                    style: 'width: 559px;height: 258px;'
                })
                // 调整光标到最后
                // 调整光标到最后
                quill.setSelection(index + 1)
                quill.focus()
                // this.setImgAttr()
            } else {
                // 提示信息，需引入Message
                this.$message.error('图片插入失败')
            }
        },
         async updatefiles(file) {
            const { url } = await cosUpload(file, false, getRandName(file.name))
            console.log('[<-- 富文本编辑器里面的url -->]',url);
            let quill = this.$refs.myTextEditor.quill
            // 如果上传成功返回图片URL
            if (quill && url) {
                // 获取光标所在位置
                let length = this.quillEditor.length // quill.getSelection().index;
                // 插入图片  res.info为服务器返回的图片地址
                quill.length = length
                let index = quill.selection.savedRange.index
                // 插入图片，res为服务器返回的图片链接地址
                quill.insertEmbed(index, 'image', {
                    url: url,
                    style: 'width: 559px;height: 258px;'
                })
                // 调整光标到最后
                // 调整光标到最后
                quill.setSelection(index + 1)
                quill.focus()
                // this.setImgAttr()
            } else {
                // 提示信息，需引入Message
                this.$message.error('图片插入失败')
            }
        },
        /** --------- 视频上传相关 start ---------  */
        insertVideoLink(videoLink) {
            if (!videoLink) return this.$message.error('视频地址不能为空！')
            this.videoForm.show = false
            let quill = this.$refs['myTextEditor'].quill
            // 获取富文本
            let length = this.quillEditor.length
            // 获取光标位置：当编辑器中没有输入文本时，这里获取到的 range 为 null
            quill.length = length
            let index = quill.selection.savedRange.index
            console.log('index', index)
            // 在光标所在位置 插入视频
            quill.insertEmbed(index, 'video', { url: videoLink })
            // 调整光标到最后
            quill.setSelection(index + 1)
            quill.focus()
        },
        // el-文件上传组件
        async onBeforeUploadVideo(file) {
            this.loading = true
            let acceptArr = ['video/mp4']
            const isVideo = acceptArr.includes(file.type)
            // const isLt1M = file.size / 1024 / 1024 < this.uploadVideoConfig.maxSize
            if (!isVideo) {
                this.hideLoading()
                this.$message.error('只能上传mp4格式文件!')
                return
            }
            const { url } = await cosUpload(file, false, file.name)
            if (url) {
                this.onSuccessVideo(url)
            }
            console.log('url', url)
        },
        // 文件上传成功时的钩子
        onSuccessVideo(url) {
            this.hideLoading()
            this.insertVideoLink(url)
        },
        hideLoading() {
            this.loading = false
        },
        /** --------- 视频上传相关 end --------- */
        setImgAttr() {
            const imgElm = document.querySelector('.ql-editor').querySelectorAll('img')
            console.log('[<-- imgElm -->]',imgElm);
            
        },
    }
}
</script>
<style lang="scss" scoped>
::v-deep .ql-formats {
    margin-right: 0px !important;
}
::v-deep .ql-insertMetric{
  background-image: url("../../assets/images/note-cut.png") !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width:16px !important;
  height: 16px !important;
  margin: 0 5px;
}
::v-deep .ql-sign{
  background-image: url("../../assets/images/note-sign.png") !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width:16px !important;
  height: 16px !important;
  margin: 0 5px;
}
::v-deep .ql-snow{
  border: 1px solid #E6E9F0;
}
</style>
