import COS from "cos-js-sdk-v5";

let cos = null;
let taskId = null;
cos = new COS({
  getAuthorization: function (options, callback) {
    // 此处不能配置测试环境的桶，否则文件会上传失败
    // const url = `${process.env.VUE_APP_API_SHANHU_ONLINE}/common/index/getCosSign`
    const url ="https://school.shkt.online/onlineapi/common/index/getCosSign";
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.onload = function (data) {
      console.log(data);
      try {
        data = JSON.parse(xhr.responseText);
        var credentials = data.data.credentials;
      } catch (e) {
        console.log(e);
      }
      if (!data || !credentials) {
        return console.error(
          "credentials invalid:\n" + JSON.stringify(data, null, 2)
        );
      }
      callback({
        TmpSecretId: credentials.tmpSecretId,
        TmpSecretKey: credentials.tmpSecretKey,
        SecurityToken: credentials.sessionToken,
        StartTime: data.data.startTime, // 时间戳，单位秒，如：1580000000
        ExpiredTime: data.data.expiredTime, // 时间戳，单位秒，如：1580000000
      });
    };
    xhr.send();
  },
  UseAccelerate: true,
});

function randomString(len) {
  len = len || 8;
  const chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  const maxPos = chars.length;
  let pwd = "";
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}

function getSuffix(filename) {
  let pos = filename.lastIndexOf(".");
  let suffix = "";
  if (pos !== -1) {
    suffix = filename.substring(pos);
  } else {
    suffix = "";
  }
  return suffix;
}

function two(num) {
  if (num < 10) {
    return "0" + num;
  }
  return num;
}

function cosUpload(file, needFid, file_name, callback) {
  console.log("file", file);
  return new Promise((resolve) => {
    const time = new Date();
    const y = time.getFullYear();
    const m = two(time.getMonth() + 1);
    const d = two(time.getDate());
    const name = file_name || file.name;
    const fileName = randomString() + "_" + name;
    const uploadPath = "/uploads/" + "-1" + "/" + y + "/" + m + "/" + d;
    const storeAs = uploadPath + "/" + fileName;
    cos.uploadFile(
      {
        Bucket: "tengface-live-user-1308721187",
        /* 填入您自己的存储桶，必须字段 */
        Region: "ap-guangzhou",
        /* 存储桶所在地域，例如ap-beijing，必须字段 */
        Key: storeAs,
        /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
        StorageClass: "STANDARD",
        Body: file, // 上传文件对象
        onTaskReady: (id) => {
          /* 非必须 */
          // this.form.file[0].taskId = taskId
          taskId = id;
        },
        onProgress: (progressData) => {
          if (callback) {
            callback(progressData, file.name);
          }
        },
      },
      async (err, data) => {
        console.log("data", data);
        if (data.statusCode === 200) {
          let url = `https://${data.Location}`;
          if (needFid) {
            const fid = await upServer(fileName, name, file.size, url);
            resolve({ url, fid });
          } else {
            resolve({ url: url });
          }
          taskId = null;
        }
        if (err) {
          console.log(err);
          // this.$set(this.enclosureForm.file[0], 'err', true)
        }
      }
    );
  });
}

function getRandName(name) {
  return randomString(10) + getSuffix(name);
}

function upServer(file_name, client_name, file_size, url) {
  return new Promise((resolve) => {
    const option = {
      file_name,
      client_name,
      file_size,
      file_type: "image/jpeg",
      url,
    };
    const api = "/customer/file/upFile";
    api.post(api, option).then((res) => {
      if (res.code === 200) {
        resolve(res.data.fid);
      }
    });
  });
}
function cancelTask() {
  if (taskId) {
    cos.cancelTask(taskId);
  }
}

export { cosUpload, cancelTask, getRandName };
