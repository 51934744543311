import axios from 'axios'
import qs from 'qs'
import router from '../router'
import store from '@/store/store'

function commApiRequest(baseURL) {
    const api = axios.create({
        baseURL: baseURL || process.env.VUE_APP_API_ONLINE_SCHOOL,
        timeout: 20000,
        withCredentials: false
    })
    
    api.interceptors.request.use(
        config => {
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
            config.data = qs.stringify({
            token: localStorage.getItem('token') || null,
            ...config.data})
            return config
        },
        err => {
            return Promise.reject(err).catch(err => console.log(err))
        }
      )
      
      // Add a response interceptor
      api.interceptors.response.use(
        response => {
            // Do something with response data
        if (response.data.code === 100068 || response.data.code === 100067) {
            // 清除本地token
            localStorage.removeItem('token')
            store.commit("user/setIScheckToken", false);
            // 记录当前页面地址，方便登登录后重定向
            // const redirectUrl = location.href
            // // 重新加载页面
            // location.href = `#/zhanghaomimadl?redirectUrl=${redirectUrl}`
            // location.reload()
    
            // 记录当前页面地址，方便登登录后重定向
            const redirectUrl = location.href
            // const redirectUrl = location.href.includes('course-home') ? location.href : ''
            router.push({ name: 'zhanghaomimadl', query: { redirectUrl } })
        }
            return response
        },
        error => {
            // Do something with response error
            return Promise.reject(error).catch(err => console.log(err))
        }
      )

    return api
}

export default commApiRequest
